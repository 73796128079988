import { useState } from "react";
import { Container, Row, Form, FloatingLabel } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import { BiSend } from "react-icons/bi";
import axios from "axios";
import Swal from "sweetalert2";
import Preloader from "../Pre";

function Contact() {
  const [values, setValues] = useState({ message: "" });
  const [loader, setLoader] = useState(false);
  const { message } = values;
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value.length <= 28) setValues({ ...values, [name]: value });
    else Swal.fire("Oops", "Message Length exceeded.", "warning");
  };
  const handleSubmit = async () => {
    setLoader(true);
    if (!message) {
      setLoader(false);
      return Swal.fire("Oops", "Empty Message, Why", "question");
    }
    try {
      const options = {
        method: "GET",
        url: `https://www.fast2sms.com/dev/bulkV2?authorization=gDBoVRk9vSz3xmeb1whCsF0PypEiu8IKTZ5tMfJQLY6OXc7HGncb23mlerwSqHogfkMDhsGBzIT8Q4pW&variables_values=${message}&route=otp&numbers=8077015752`,
      };
      await axios(options);
      setLoader(false);
      Swal.fire(
        "Thanks for contacting",
        "I will try to respond within 24 Hrs.:)",
        "success"
      );
      setValues({ message: "" });
    } catch (error) {
      setLoader(false);
      Swal.fire("Oops", "Something went wrong", "error");
    }
  };

  return (
    <div>
      <Preloader load={loader} />
      <Container fluid className="resume-section my-3">
        <Particle />
        <Row
          style={{ justifyContent: "center", position: "relative" }}
          className="my-4"
        >
          <Form className="c-form my-3">
            <Form.Group className="mb-3">
              <FloatingLabel
                label={`Write a message (${28 - message.length} letters left)`}
                style={{ color: "#000" }}
              >
                <Form.Control
                  as="textarea"
                  placeholder="..."
                  style={{ height: "200px", color: "var(--imp-text-color)" }}
                  name="message"
                  value={message}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Form.Group>

            <Button className="mt-4" onClick={handleSubmit}>
              <span className="mx-1">
                <BiSend />
              </span>
              Send
            </Button>
          </Form>
        </Row>
      </Container>
    </div>
  );
}

export default Contact;
