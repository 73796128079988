import React from "react";
import { Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  SiLinux,
  SiVisualstudiocode,
  SiPostman,
  SiAmazonaws,
  SiDocker,
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <OverlayTrigger placement={"top"} overlay={<Tooltip>Linux</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <SiLinux />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger
        placement={"top"}
        overlay={<Tooltip>Visual Studio Code</Tooltip>}
      >
        <Col xs={4} md={2} className="tech-icons">
          <SiVisualstudiocode />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement={"top"} overlay={<Tooltip>Postman</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <SiPostman />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement={"top"} overlay={<Tooltip>AWS</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <SiAmazonaws />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger
        placement={"top"}
        overlay={<Tooltip>Docker</Tooltip>}
      >
        <Col xs={4} md={2} className="tech-icons">
          <SiDocker />
        </Col>
      </OverlayTrigger>
    </Row>
  );
}

export default Toolstack;
