import { useState, useRef } from "react";
import { Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiGit,
} from "react-icons/di";
import {
  SiRedux,
  SiTypescript,
  SiNextdotjs,
  SiRedis,
  SiPostgresql,
} from "react-icons/si";

function Techstack() {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <OverlayTrigger placement={"top"} overlay={<Tooltip>JavaScript</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <DiJavascript1 />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement={"top"} overlay={<Tooltip>TypeScript</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <SiTypescript />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement={"top"} overlay={<Tooltip>Node.js</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <DiNodejs />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement={"top"} overlay={<Tooltip>React.js</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <DiReact />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement={"top"} overlay={<Tooltip>MongoDB</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <DiMongodb />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement={"top"} overlay={<Tooltip>Next.js</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <SiNextdotjs />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement={"top"} overlay={<Tooltip>Git</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <DiGit />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement={"top"} overlay={<Tooltip>Redux</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <SiRedux />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement={"top"} overlay={<Tooltip>Redis</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <SiRedis />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement={"top"} overlay={<Tooltip>PostgreSQL</Tooltip>}>
        <Col xs={4} md={2} className="tech-icons">
          <SiPostgresql />
        </Col>
      </OverlayTrigger>
    </Row>
  );
}

export default Techstack;
