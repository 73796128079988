import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import pod from "../../Assets/Projects/pod.png";
import insta from "../../Assets/Projects/insta.png";
import afe from "../../Assets/Projects/afe.png";
import alasca from "../../Assets/Projects/alasca.png";
import mgrant from "../../Assets/Projects/mgrant.png";
import cityfinance from "../../Assets/Projects/cityfinance.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={alasca}
              isBlog={false}
              title="Alasca Fashion"
              description="A full-fledged online fashion retail platform, used technolgies: Node.js React.js, MongoDB, Express.js, Material-UI, Razorpay Checkout, Cloudinary. Developed Search, Sort, Filter, Pagination, Authentication."
              link="https://alascafashion.in"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={cityfinance}
              isBlog={false}
              title="City Finance"
              description="Cityfinance.in serves as a national framework of standardized, timely and credible financial information on cities. It facilitates benchmarking, comparison and peer learning between cities on a range of financial indicators."
              link="https://cityfinance.in"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={afe}
              isBlog={false}
              title="Amazon Future Engineer Class Chats"
              description="Encourage your students to pursue a career in STEM by bringing an Amazon professional to the classroom. Our Class Chat volunteers will share their personal career story and insight into work in tech and answer your students' career questions."
              link="https://afe-classchats.in/become-an-afe-partner"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={pod}
              isBlog={false}
              title="PartyOnDoor"
              description="A platform where a user can showcase his services through posts, and interested customers of their city can contact them for their services. Used Technologies Next.js, MongoDB, Cloudinary, Vercel, Nodemailer, Email.js, Fast2Sms..."
              link="https://partyondoor-theta.vercel.app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={mgrant}
              isBlog={false}
              title="MGrant"
              description="MGrant is a web based solution enabling organizations to manage their CSR life cycles. The mobile application allows the grantees and partners to evaluate their achievements  against their set targets. It assists the CSRs and donors to manage their end to end project lifecycle."
              link="https://mgrant.dhwaniris.in"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={insta}
              isBlog={false}
              title="Instagram Clone"
              description="A web App which has some features of instagram web, and almost same User Interface. What i used to create I have used NodeJS, MongoDB, Express, React for the backend and the frontend . For the authentication: i have used a package called jsonwebtoken For user's credentials security."
              link="https://github.com/cpbhai/instagram-clone"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
